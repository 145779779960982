import gsap from "gsap"

export function gsapAnimations() {
  const animationConfigs = [
    {
      selector: ".js-fade-from-left",
      animation: { x: 0, y: "unset", duration: 0.8 },
      start: "top 65%",
    },
    {
      selector: ".js-fade-from-bottom",
      animation: { x: "unset", y: 0, duration: 0.5 },
      start: "top 80%",
    },
  ]

  animationConfigs.forEach(({ selector, animation, start }) => {
    const elements = document.querySelectorAll(selector)

    elements.forEach((element) => {
      gsap.fromTo(
        element,
        {
          opacity: 0,
          x: animation.x === 0 ? -100 : "unset",
          y: animation.y === 0 ? 50 : "unset",
        },
        {
          opacity: 1,
          ...animation,
          scrollTrigger: {
            trigger: element,
            start,
            toggleActions: "play none none none",
          },
        },
      )
    })
  })

  // Fade in .newsletter-icon from the bottom and add "shake" class
  const newsletterIcon = document.querySelector(".newsletter-icon")
  if (newsletterIcon) {
    gsap.fromTo(
      newsletterIcon,
      {
        opacity: 0,
        y: 50,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        scrollTrigger: {
          trigger: newsletterIcon,
          start: "top 75%",
          toggleActions: "play none none none",
        },
        onComplete: () => {
          newsletterIcon.classList.add("_shake")
        },
      },
    )
  }

  // hero image elements animation
  const heroImageElements = document.querySelectorAll(".hero-image-el")

  heroImageElements.forEach((element, index) => {
    const parent = element.closest(".hero-image")

    const rotateValue = element.getAttribute("data-rotate") || 0
    const topValue = element.getAttribute("data-top") || "unset"
    const bottomValue = element.getAttribute("data-bottom") || "unset"

    gsap.fromTo(
      element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        rotate: rotateValue,
        top: topValue,
        bottom: bottomValue,
        duration: 0.5,
        scrollTrigger: {
          trigger: parent,
          start: "top 50%",
          toggleActions: "play none none none",
        },
        delay: index >= heroImageElements.length - 4 ? 0.1 : 0,
      },
    )
  })

  // how it works section elements
  const howItWorksElements = document.querySelectorAll(".how-it-works-animated")

  howItWorksElements.forEach((element, index) => {
    let timeline = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: index === 2 ? "top 70%" : "top 50%",
        toggleActions: "play none none none",
      },
    })

    if (index === 0) {
      gsap.to(element.querySelector(".how-it-works-animated__header-icon._blue"), {
        scrollTrigger: {
          trigger: element.querySelector(".how-it-works-animated__header-icon._blue"),
          start: "top 50%",
          onEnter: function () {
            element
              .querySelector(".how-it-works-animated__header-icon._blue")
              .classList.add("_shadow")
          },
        },
      })

      gsap.to(element.querySelector(".how-it-works-animated-dialog__button._teal"), {
        scrollTrigger: {
          trigger: element.querySelector(".how-it-works-animated-dialog__button._teal"),
          start: "top 80%",
          onEnter: function () {
            element
              .querySelector(".how-it-works-animated-dialog__button._teal")
              .classList.add("_shadow")
          },
        },
      })
    } else if (index === 1) {
      timeline
        .to(element.querySelector(".how-it-works-animated__content-top"), {
          y: 0,
          duration: 0.8,
          ease: "power2.out",
        })
        .to(
          element.querySelector(".how-it-works-animated-bot__image"),
          {
            y: 0,
            duration: 0.8,
            ease: "power2.out",
          },
          0,
        )
        .to(element.querySelector(".how-it-works-animated__content"), {
          y: "-41%",
          duration: 2,
          ease: "power4.inOut",
        })
    } else if (index === 2) {
      timeline.to(element.querySelector(".how-it-works-animated__content"), {
        y: "-32.5%",
        duration: 1.5,
        ease: "power4.inOut",
      })
    } else if (index === 3) {
      timeline.to(element.querySelector(".how-it-works-animated__content"), {
        y: "-49.9%",
        duration: 1.5,
        ease: "power4.inOut",
      })
    } else {
      return
    }
  })
}
