import { Form } from "../components/forms/form"

export class Newsletter extends Form {
  constructor() {
    super()

    this.newsletterForm = document.querySelector(".newsletter-form")

    this.newsletterForm && this.init()
  }

  init() {
    this.newsletterForm.addEventListener("submit", (e) => this.onSubmit(e))
  }

  onSubmit(e) {
    e.preventDefault()

    const formData = new FormData(this.newsletterForm)
    const data = Object.fromEntries(formData.entries())

    if (this.isValid) {
      console.log("Submitted form data:", data)
    }
  }
}
