import gsap from "gsap"
import ScrollTrigger from "gsap/src/ScrollTrigger"
import { gsapAnimations } from "./lib/gsap"
import { convertLinkToTelPattern } from "./utils/tel-link"
import { Header } from "./modules/header"
import { contentSwitchers } from "./modules/content-switchers"
import { Navigation } from "./modules/navigation"
import { Accordion } from "./components/accordions/accordion"
import { footer } from "./modules/footer"
import { Newsletter } from "./modules/newsletter"

/**
 * Show hidden elements as modals, popups, tooltips, etc.
 * In a first load webpage html elements loads before styles. That's all
 * elements with transition is jumping on a page.
 * @type { NodeListOf<HTMLElement> }
 * */
const hiddenElements = document.querySelectorAll(".important_none")

window.addEventListener("load", async () => {
  gsap.registerPlugin(ScrollTrigger)

  hiddenElements.forEach(($el) => $el.classList.remove("important_none"))
  const linksToCheckTelPattern = document.querySelectorAll("a")
  linksToCheckTelPattern &&
    linksToCheckTelPattern.forEach((linkTel) => convertLinkToTelPattern(linkTel))

  gsapAnimations()

  new Header()
  contentSwitchers()
  new Navigation()
  new Accordion()
  new Newsletter()
  footer()
})
