import { ScrollbarLocker } from "../lib/scrollbar-locker"

export class Navigation {
  constructor() {
    /** @type { HTMLElement } */
    this.$nav = document.querySelector(".nav")
    if (!this.$nav) {
      return console.warn("Cannot initialize navigation without nav", this)
    }

    /** @type { NodeListOf<HTMLButtonElement> } */
    this.triggers = document.querySelectorAll(".js-nav-trigger")
    this.links = this.$nav.querySelectorAll(".nav__link")

    this.navOpenClass = "_show"
    this.triggerActiveClass = "_active"

    /** @type { boolean } */
    this.isOpen = false

    /** @type { number } */
    this.scrollPosition = 0 // Store scroll position as a class property

    this.init()
  }

  init() {
    this.triggers.forEach(($trigger) => {
      $trigger.addEventListener("click", this.triggerClickHandle)
    })

    this.links.forEach((link) => {
      link.addEventListener("click", this.linkClickHandle)
    })

    // Listen for window resize and orientation changes
    window.addEventListener("resize", this.handleResize)

    const mediaQuery = window.matchMedia("(orientation: landscape)")
    mediaQuery.addEventListener("change", this.handleResize)

    // Initial check for resize or orientation
    // this.handleResize()
  }

  triggerClickHandle = () => {
    this.toggle()
  }

  linkClickHandle = () => {
    this.close()
  }

  toggle() {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.scrollPosition = window.scrollY
    ScrollbarLocker.lock()
    document.body.style.position = "fixed"
    document.body.style.top = `-${this.scrollPosition}px` // Fix scroll position
    document.body.style.width = "100%"

    this.$nav.classList.add(this.navOpenClass)

    this.triggers.forEach(($trigger) => {
      $trigger.classList.add(this.triggerActiveClass)
    })

    this.isOpen = true
  }

  close() {
    document.documentElement.style.scrollBehavior = "auto"
    document.body.style.position = ""
    document.body.style.top = ""
    document.body.style.width = ""
    window.scrollTo(0, this.scrollPosition)
    setTimeout(() => {
      document.documentElement.style.scrollBehavior = "smooth"
    }, 0)

    ScrollbarLocker.unLock()

    this.$nav.classList.remove(this.navOpenClass)

    this.triggers.forEach(($trigger) => {
      $trigger.classList.remove(this.triggerActiveClass)
    })

    this.isOpen = false
  }

  handleResize = () => {
    if (window.innerWidth > 767 || window.matchMedia("(orientation: landscape)").matches) {
      this.close()
    }
  }
}
