export function contentSwitchers() {
  const switchers = [
    { elements: document.querySelectorAll(".js-switch-driver"), bodyClass: "_driver-content" },
    {
      elements: document.querySelectorAll(".js-switch-passenger"),
      bodyClass: "_passenger-content",
    },
  ]

  switchers.forEach(({ elements, bodyClass }) => {
    elements.forEach((element) => {
      element.addEventListener("click", () => {
        switchers.forEach(({ elements: otherElements, bodyClass: otherClass }) => {
          otherElements.forEach((el) => el.classList.toggle("_active", otherClass === bodyClass))
          document.body.classList.toggle(otherClass, otherClass === bodyClass)
        })
      })
    })
  })
}
